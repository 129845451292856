<template>
  <v-dialog
    v-if="order"
    v-model="show"
    persistent
    max-width="60%"
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card class="br-16 action-modal">
      <v-card-title
        class="px-4 py-3 primary font-weight-bold secondary--text text-center"
      >
        <span>Order #{{ order.order_no }} Confirmation</span>
      </v-card-title>
      <v-card-text class="action-modal-content px-8 py-4">
        <v-row class="mb-6">
          <v-col
            cols="12"
            class="br-8 customer-container align-center secondary"
          >
            <div
              v-for="(el, i) in top_level_data"
              :key="i"
              :class="`element w-auto ${el.class}`"
            >
              <v-icon
                class="icon"
                :class="{ hidden: !el.text, [el.icon_class]: true }"
                v-text="el.icon"
              />
              <span>{{ el.text }}</span>
            </div>
            <div class="element w-auto text-body-1">
              <template v-if="order.customer.accept_marketing_emails">
                <v-icon class="mr-2">mdi-check</v-icon
                ><i>Accepted to receive marketing emails.</i>
              </template>
              <template v-else>
                <i>Do not wish to receive marketing emails. </i>
              </template>
            </div>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col col="12" md="5">
            <v-date-picker
              class="datepicker-border"
              v-model="service_date"
              flat
              no-title
              :min="min_fulfilment_date"
            />
          </v-col>
          <v-col col="12" md="7">
            <p class="text-h5 mb-2 text-capitalize">
              <span class="font-weight-bold mr-1">Service Type: </span>
              <v-icon class="mr-1" v-text="`$${order.service}`"></v-icon>
              {{ order.service }}
            </p>
            <p class="text-h5 mb-2">
              <span class="font-weight-bold mr-1">Requested Date: </span
              ><span class="mr-2 borderd">{{ format_date(service_date) }}</span>
              <i class="primary--text" v-if="!fulfilment_data.is_confirmed"
                >Unconfirmed</i
              >
            </p>
            <p class="text-h5 mb-2">
              <span class="font-weight-bold mr-1">Requested Time: </span
              ><v-select
                class="d-inline-block text-h5 time-select mr-2"
                style="width: 75px"
                hide-details
                dense
                v-model="service_time"
                :items="service_time_range"
              >
              </v-select>
              <i class="primary--text" v-if="!fulfilment_data.is_confirmed"
                >Unconfirmed</i
              >
            </p>
            <v-textarea
              hide-details
              class="email-message br-8"
              style="border-bottom: none"
              placeholder="Email confirmation message for your customer."
              v-model="email_message"
              maxlength="400"
              height="183"
              no-resize
              filled
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end px-8 mb-3">
        <v-btn class="" color="darkgrey" rounded text @click="show = undefined"
          >Back to order</v-btn
        >
        <v-btn class="" color="darkgrey" rounded text @click="show = false"
          >Close</v-btn
        >
        <v-btn
          rounded
          elevation="0"
          class="primary-alt text-uppercase mr-0"
          :disabled="!selected_date || updating_fulfilment_date"
          @click="confirm_fulfilment()"
        >
          <v-progress-circular
            v-if="updating_fulfilment_date"
            :size="20"
            indeterminate
            color="white"
          />
          {{ updating_fulfilment_date ? null : "Confirm Request" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import range from "lodash/range";

import { SERVICE_TYPES_INFO } from "@/shared/services";
import {
  get_HHmm_time_as_seconds,
  get_seconds_as_HHmm,
} from "@/utils/date_utils";

export default {
  name: "ConfirmFulfilmentDate",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      default: null,
    },
    updating_fulfilment_date: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      SERVICE_TYPES_INFO,
      min_fulfilment_date: moment().format("YYYY-MM-DD"),
      email_message: "",
      selected_date: null,
      selected_time: null,
      moment,
    };
  },
  computed: {
    ...mapState("AdminStore", ["shop_id", "shop"]),
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (value === false) {
          this.reset();
          this.$emit("close");
        } else if (value === undefined) {
          this.reset();
          this.$emit("back_to_order");
        }
      },
    },
    service_date: {
      get() {
        const selected_date = this.selected_date;
        if (selected_date) return selected_date;

        return this.fulfilment_data?.data ?? moment().format("YYYY-MM-DD");
      },
      set(date) {
        this.selected_date = date;
      },
    },
    service_time: {
      get() {
        const selected_time = this.selected_time;
        if (selected_time) return selected_time;
        return (
          this.fulfilment_data?.time ??
          moment().add({ hours: 1 }).format("HH:00")
        );
      },
      set(time) {
        this.selected_time = time;
      },
    },
    service_time_range() {
      const seconds_in_15_mins = 900;
      const available_times_in_seconds = range(
        get_HHmm_time_as_seconds("00:00"),
        get_HHmm_time_as_seconds("24:00"),
        seconds_in_15_mins
      );

      return available_times_in_seconds.map(get_seconds_as_HHmm);
    },
    is_retail_order: function () {
      const order = this.order;
      return [
        SERVICE_TYPES_INFO.delivery.id,
        SERVICE_TYPES_INFO.collection.id,
      ].includes(order.service);
    },
    top_level_data() {
      const order = this.order;

      return [
        { text: order.customer.name, icon: "mdi-account" },
        { text: order.customer.email, icon: "mdi-email" },
        { text: order.customer.phone_no, icon: "mdi-phone" },
      ];
    },
    fulfilment_data() {
      if (!this.order) return;

      let fulfilment_date = null;
      let is_asap = null;
      let date = null;
      let time = null;

      if (this.order.service === "in_house") {
        return {
          is_used: false,
        };
      } else {
        fulfilment_date = this.order.fulfilment_date;
        is_asap = !fulfilment_date;
      }

      const fulfilment_confirmations = this.order.confirmations?.filter(
        (c) => c.type === "fulfilment_date"
      );

      if (!is_asap) [date, time] = fulfilment_date.split(" ");

      return {
        is_used: true,
        is_confirmed: fulfilment_confirmations.length > 0,
        is_asap,
        date,
        time,
      };
    },
  },
  watch: {
    order: function () {
      this.selected_date = this.fulfilment_data?.date ?? this.service_date;
      this.selected_time = this.fulfilment_data?.time ?? this.service_time;
      this.email_message = "";
    },
  },
  methods: {
    reset() {
      this.email_message = "";
      this.selected_date = null;
      this.selected_time = null;
    },
    format_date(date) {
      return moment(date, "YYYY-MM-DD").format("Do MMMM YYYY");
    },
    confirm_fulfilment() {
      const fulfilment_date = this.selected_time
        ? `${this.selected_date} ${this.selected_time}`
        : this.selected_date;
      this.$emit("confirm_fulfilment_date", {
        brand_id: this.order.brand_id,
        order_id: this.order.id,
        shop_name: this.shop.name,
        fulfilment_date: fulfilment_date,
        email_message: this.email_message,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .datepicker-border {
  border: 2px solid #eee;
  border-radius: 8px;
}
.email-message > .v-input__control > .v-input__slot:before {
  content: none;
}
.email-message > .v-input__control > .v-input__slot:after {
  // width: 101%;
  height: 2px;
  bottom: 0px;
  left: 0px;
}
.borderd {
  border-bottom: 1px solid #333;
}
.time-select {
  &::v-deep.v-text-field > .v-input__control > .v-input__slot:after {
    bottom: 2px;
  }
  &::v-deep.v-text-field > .v-input__control > .v-input__slot:before {
    bottom: 2px;
    border-color: #333333;
  }
}
</style>
