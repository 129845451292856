<template>
  <v-dialog
    id="order-details"
    v-if="order"
    v-model="show"
    max-width="90%"
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card class="br-16 action-modal">
      <v-card-title
        class="
          details-title
          px-6
          py-3
          primary
          font-weight-bold
          secondary--text
          d-flex
          justify-space-between
          flex-no-wrap
        "
      >
        <span>Order #{{ order.order_no }}</span>
        <div
          v-if="order.service !== 'in_house'"
          class="
            service
            text-capitalize
            justify-center
            text-center
            align-center
          "
        >
          <v-icon color="white" class="mr-2" v-text="`$${order.service}`" />{{
            order.service === "hospitality_delivery"
              ? "Delivery"
              : order.service
          }}
        </div>
        <div
          v-else
          class="
            service
            text-capitalize
            justify-center
            text-center
            align-center
          "
        >
          {{
            order.service_method === "table_service"
              ? `TABLE ${order.table_number}`
              : "Collection Point"
          }}
        </div>
        <span>from {{ format_date(order.created_at) }}</span>
      </v-card-title>

      <v-card-text class="action-modal-content">
        <v-row class="px-4 pt-3 mb-4">
          <v-col cols="12" md="6" class="br-8 customer-container">
            <div
              v-for="(el, i) in top_level_data"
              :key="i"
              :class="`element  ${el.class ? el.class : ''}`"
            >
              <v-icon
                class="icon"
                :class="{
                  hidden: !el.text,
                  [el.icon_class]: true,
                }"
                v-text="el.icon"
                color="white"
              />
              <a
                v-if="el.href"
                :href="el.href"
                target="_blank"
                class="text--secondary"
              >
                <span>{{ el.text }}</span>
              </a>
              <span v-else>{{ el.text }}</span>
            </div>
            <div class="element w-100" v-if="order.customer.notes">
              <v-badge
                color="accent"
                top
                offset-x="25"
                offset-y="12"
                content="1"
                ><v-icon class="icon mr-4">mdi-message</v-icon></v-badge
              ><i>"{{ order.customer.notes }}"</i>
            </div>
            <div v-if="!order.is_shared" class="element w-100 text-body-1">
              <template v-if="order.customer.accept_marketing_emails">
                <v-icon class="mr-">mdi-check</v-icon
                ><i>Accepted to receive marketing emails.</i>
              </template>
              <template v-else>
                <i>Do not wish to receive marketing emails. </i>
              </template>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="text-right"
            :class="{ 'pt-0': $vuetify.breakpoint.mdAndUp }"
          >
            <div
              v-if="
                order.collaboration_status && order.collaboration_status.status
              "
              class="
                d-print-none d-inline-block
                yellow--border
                br-16
                py-4
                px-6
                mb-4
              "
            >
              <p class="black--text font-weight-bold mb-2">
                Collaboration Status
              </p>
              <div class="">
                <p class="mb-0 text-h6">
                  {{
                    moment
                      .unix(order.collaboration_status.updated_at.seconds)
                      .format("DD/MM/YYYY HH:mm")
                  }}
                </p>
                <div v-if="is_collab_expired">Collaboration offert expired</div>
                <div v-else>
                  <p class="my-0">
                    {{
                      order.collaboration_status.status === "new"
                        ? "Order ready & open"
                        : order.collaboration_status.status
                    }}
                    <span v-if="order.collaboration_status.claimed_by">
                      by {{ order.collaboration_status.claimed_by }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <v-chip
              v-else
              class="px-6 mb-4 text-capitalize white--text"
              pill
              :color="order.status"
              ><span class="font-weight-bold text-h5">{{
                order.status.replaceAll("_", " ")
              }}</span></v-chip
            >
            <template v-if="fulfilment_data.is_used">
              <div
                v-if="!fulfilment_data.is_confirmed && fulfilment_data.is_asap"
                class="text-right"
              >
                <p class="d-inline-block mb-0">
                  <i class="primary--text font-weight-bold mr-2"
                    >Unconfirmed Date & Time</i
                  >
                </p>
              </div>
              <div v-if="fulfilment_data.date" class="text-right">
                <v-icon
                  small
                  class="d-inline-block mr-2"
                  color="primary"
                  v-if="fulfilment_data.is_confirmed"
                  >mdi-check</v-icon
                >
                <p class="d-inline-block mb-0">
                  <i
                    v-if="!fulfilment_data.is_confirmed"
                    class="primary--text font-weight-bold mr-2"
                    >Unconfirmed</i
                  ><span class="font-weight-bold">Service Date: </span
                  >{{ format_iso_date_to_uk_date(fulfilment_data.date) }}
                </p>
              </div>
              <div v-if="fulfilment_data.time" class="text-right">
                <v-icon
                  small
                  class="d-inline-block mr-2"
                  color="primary"
                  v-if="fulfilment_data.is_confirmed"
                  >mdi-check</v-icon
                >
                <p class="d-inline-block mb-0">
                  <i
                    v-if="!fulfilment_data.is_confirmed"
                    class="primary--text font-weight-bold mr-2"
                    >Unconfirmed</i
                  ><span class="font-weight-bold">Service Time: </span
                  >{{ fulfilment_data.time }}
                </p>
              </div>
              <v-btn
                v-if="!order.is_shared"
                rounded
                elevation="0"
                class="primary-alt mt-3 mr-0"
                @click="$emit('confirm_order')"
              >
                <v-icon small class="mr-2">$chat</v-icon
                >{{
                  fulfilment_data.is_confirmed
                    ? "Reschedule Order"
                    : "Confirm Order"
                }}
              </v-btn>
            </template>
          </v-col>
        </v-row>

        <v-simple-table v-if="products.length">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center" v-if="is_retail_order">#</th>
                <th>
                  <v-tooltip bottom content-class="custom-tooltip">
                    <template v-slot:activator="{ on }">
                      <span
                        class="d-inline-flex d-print-none"
                        style="margin-left: -5px"
                      >
                        <v-icon v-on="on">mdi-information-outline</v-icon>
                      </span>
                    </template>
                    <span
                      >Use the checkboxes to tick off products as you complete
                      them</span
                    >
                  </v-tooltip>
                  <v-checkbox
                    color="primary"
                    dense
                    class="d-inline-flex ml-2 d-print-none"
                    v-model="all_products_checked"
                  ></v-checkbox>
                </th>
                <th class="text-left">Category</th>

                <th class="text-left">Items ({{ order.products.length }})</th>
                <th class="text-center">Qty</th>
                <th class="text-center">Base Price (£)</th>
                <th class="text-right">Total (£)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v, k) in products" :key="k">
                <td class="text-center" v-if="is_retail_order">
                  {{ String(k + 1) }}
                </td>
                <td class="text-center">
                  <v-checkbox
                    color="primary"
                    dense
                    class="ml-6 pl-1 d-print-none"
                    v-bind:input-value="
                      products_checked.indexOf(v.product_id) !== -1
                    "
                    @change="
                      (value) =>
                        handle_product_checkbox_change(value, v.product_id)
                    "
                  ></v-checkbox>
                </td>
                <td class="text-left">{{ v.category }}</td>

                <td
                  class="text-left"
                  :class="{
                    'line-through':
                      products_checked.indexOf(v.product_id) !== -1,
                  }"
                >
                  <i v-if="v._shared_by" class="font-weight-bold mr-2"
                    >({{ v._shared_by.name }})</i
                  >{{ v.name }}
                  <ul>
                    <li
                      v-for="(option, idx) in list_product_options(v)"
                      :key="`${k}_${idx}`"
                      v-html="option"
                    ></li>
                  </ul>
                </td>
                <td class="text-center">{{ v.quantity }}</td>
                <td class="text-center">{{ v.price.toFixed(2) }}</td>
                <td class="text-right">{{ v.total_price.toFixed(2) }}</td>
              </tr>
              <tr v-if="order.custom_fee" class="border-none">
                <td :colspan="is_retail_order ? 5 : 4"></td>
                <td class="text-center title">
                  <strong>{{ order.custom_fee_name }}</strong>
                </td>
                <td class="text-right">
                  {{ order.custom_fee.toFixed(2) }}
                </td>
              </tr>
              <tr v-if="order.discount" class="border-none">
                <td :colspan="is_retail_order ? 5 : 4"></td>
                <td class="text-center title">
                  <strong>Discount "{{ order.active_voucher.code }}"</strong>
                </td>
                <td class="text-right">-{{ order.discount.toFixed(2) }}</td>
              </tr>
              <tr
                v-if="
                  [
                    SERVICE_TYPES_INFO.delivery.id,
                    SERVICE_TYPES_INFO.hospitality_delivery.id,
                  ].includes(order.service)
                "
                class="border-none"
              >
                <td :colspan="is_retail_order ? 5 : 4"></td>
                <td class="text-center title">
                  <strong>Delivery Fee</strong>
                </td>
                <td class="text-right">
                  {{ order.delivery_fee.toFixed(2) }}
                </td>
              </tr>
              <tr v-if="order.tip" class="border-none">
                <td :colspan="is_retail_order ? 5 : 4"></td>
                <td class="text-center title">
                  <strong>Tip</strong>
                </td>
                <td class="text-right">
                  {{ order.tip.toFixed(2) }}
                </td>
              </tr>
              <tr class="border-none">
                <td :colspan="is_retail_order ? 5 : 4"></td>
                <td class="text-center title">
                  <strong><u>TOTAL</u></strong>
                </td>
                <td class="text-right">
                  <u>{{ order.total.toFixed(2) }} </u>
                </td>
              </tr>
              <tr class="border-none" v-if="order.application_fee_amount">
                <td :colspan="is_retail_order ? 5 : 4"></td>
                <td class="text-center title">
                  <strong><u>Transaction Fee</u></strong>
                </td>
                <td class="text-right">
                  <u
                    >{{
                      (Number(order.application_fee_amount) / 100).toFixed(2)
                    }}
                  </u>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div id="signatures-box" class="d-none d-print-block pt-6">
          <v-text-field label="Packed by" outlined></v-text-field>
          <v-text-field label="Delivered by" outlined></v-text-field>
        </div>
      </v-card-text>

      <v-card-actions class="justify-end d-print-none">
        <v-btn
          class="mx-1"
          fab
          elevation="0"
          color="transparent"
          @click="print_order()"
          ><v-icon>mdi-printer</v-icon></v-btn
        >
        <v-btn class="mr-2" rounded text @click="show = false">Close</v-btn>

        <OrderDetailsActions
          v-if="!order.is_shared"
          :order="order"
          :update_order_cb="update_order"
          :props="{ color: 'primary' }"
          @close-modal="show = false"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import { db, firestore } from "@/db";
import { SERVICE_TYPES_INFO, DINING_SERVICE_TYPES } from "@/shared/services";
import OrderDetailsActions from "./OrderDetailsActions";
import orderBy from "lodash/orderBy";
import { format_iso_date_to_uk_date } from "@/utils/date_utils";

export default {
  name: "OrderDetails",
  components: {
    OrderDetailsActions,
  },
  props: {
    order: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      moment,
      SERVICE_TYPES_INFO,
      DINING_SERVICE_TYPES,
      format_iso_date_to_uk_date,
      loading: false,
      orders_products_checklist: {},
    };
  },
  computed: {
    ...mapState("AdminStore", ["orders", "shop_id"]),
    products() {
      return orderBy(
        this.order.products,
        [(p) => p?.category?.toLowerCase() ?? ""],
        ["asc"]
      );
    },
    show: {
      get() {
        const show = this.order !== null;
        if (show) {
          // Get products checklist from local storage
          this.get_orders_products_checklist();
        }
        return show;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    products_checked: {
      get() {
        return this.orders_products_checklist[this.order?.id] || [];
      },
      set(value) {
        let orders_products_checked = new Object();
        orders_products_checked[this.order?.id] = value;

        this.orders_products_checklist = {
          ...this.orders_products_checklist,
          ...orders_products_checked,
        };
      },
    },
    all_products_checked: {
      get() {
        return this.order
          ? this.products_checked.length === this.order.products.length
          : false;
      },
      set(checked) {
        const products = this.order.products;
        let products_checked = [];

        if (checked) {
          products_checked = products.map(function (order) {
            return order.product_id;
          });
        }

        this.products_checked = products_checked;

        // Update local storage
        this.update_orders_products_checklist();
      },
    },
    is_collab_expired: function () {
      if (this.order?.collaboration_status?.status === "new") {
        return moment().isAfter(moment(this.order.fulfilment_date), "day");
      }
      return false;
    },
    is_retail_order: function () {
      const order = this.order;
      return [
        SERVICE_TYPES_INFO.delivery.id,
        SERVICE_TYPES_INFO.collection.id,
      ].includes(order.service);
    },
    is_delivery_order() {
      return [
        SERVICE_TYPES_INFO.delivery.id,
        SERVICE_TYPES_INFO.hospitality_delivery.id,
      ].includes(this.order.service);
    },
    top_level_data() {
      const order = this.order;

      if (order.is_shared) {
        return [
          {
            text: "Through collaboration with " + order._from.name,
            icon: "mdi-share",
          },
          { text: order._from.email, icon: "mdi-email" },
          { text: order.customer.phone_no, icon: "mdi-phone", class: "w-100" },
          { text: order.customer.mobile_no, icon: "mdi-phone", class: "w-100" },
        ];
      }

      return [
        { text: order.customer.name, icon: "mdi-account" },
        { text: order.customer.email, icon: "mdi-email" },
        { text: order.customer.phone_no, icon: "mdi-phone", class: "w-100" },
        {
          text: order?.delivery?.address ?? null,
          class: `w-100 ${!this.is_delivery_order ? "d-none" : ""}`,
          icon: "mdi-map-marker",
          href: this.is_delivery_order && this.google_maps_url(order.delivery),
        },
      ];
    },
    fulfilment_data() {
      let fulfilment_date = null;
      let is_asap = null;
      let date = null;
      let time = null;

      if (this.order.service === "in_house") {
        return {
          is_used: false,
        };
      } else {
        fulfilment_date = this.order.fulfilment_date;
        is_asap = !fulfilment_date;
      }

      const fulfilment_confirmations = this.order.confirmations?.filter(
        (c) => c.type === "fulfilment_date"
      );

      if (!is_asap) [date, time] = fulfilment_date.split(" ");

      return {
        is_used: true,
        is_confirmed: fulfilment_confirmations.length > 0,
        is_asap,
        date,
        time,
      };
    },
  },
  methods: {
    format_date(created_at) {
      // Although created_at is timestamp, firebase returns epoch
      if (!created_at) return;

      return moment.unix(created_at.seconds).format("DD/MM/YYYY H:mma");
    },
    get_confirmations_length(confirmations) {
      if (!confirmations) return null;

      return confirmations.length;
    },
    print_order() {
      const doc_title = document.title;
      const new_title = `order_${this.order.order_no}`;

      window.onafterprint = () => {
        document.title = doc_title;
      };

      document.title = new_title;
      window.print();
    },
    get_orders_products_checklist() {
      this.orders_products_checklist =
        JSON.parse(window.localStorage.getItem("orders_products_checklist")) ||
        new Object();
    },
    handle_product_checkbox_change(new_value, product_id) {
      new_value
        ? this.products_checked.push(product_id)
        : this.products_checked.splice(
            this.products_checked.indexOf(product_id),
            1
          );

      // Create new object to be tracked by computed
      let orders_products_checked = new Object();
      orders_products_checked[this.order.id] = this.products_checked;

      this.orders_products_checklist = {
        ...this.orders_products_checklist,
        ...orders_products_checked,
      };
      // Update local storage
      this.update_orders_products_checklist();
    },
    update_orders_products_checklist() {
      window.localStorage.setItem(
        "orders_products_checklist",
        JSON.stringify(this.orders_products_checklist)
      );
    },
    list_product_options(product) {
      if (!product.options) return;
      if (product?.options.length === 0) return [];
      return product?.options.map((o) => {
        if (o.option)
          return `${o.option}: <span class="font-weight-bold">${o.name}</span>`;
        return o.name;
      });
    },
    async update_order(
      order_id,
      update_params,
      modal_params = { close_modal: false }
    ) {
      this.loading = true;

      const order_ref = db
        .collection("shops")
        .doc(this.shop_id)
        .collection("orders")
        .doc(order_id);

      try {
        await db.runTransaction(async (transaction) => {
          const order_doc = await transaction.get(order_ref);
          if (!order_doc.exists) {
            throw "Document does not exist";
          }
          transaction.update(order_ref, {
            ...update_params,
            updated_at: firestore.FieldValue.serverTimestamp(),
          });
        });
      } catch (error) {
        console.error("Transaction failed", error);
      } finally {
        this.loading = false;
      }

      if (modal_params.close_modal) this.show = false;
    },
    google_maps_url({ geolocation }) {
      return `https://www.google.com/maps/search/?api=1&query=${geolocation.lat},${geolocation.lng}`;
    },
  },
};
</script>

<style lang="scss">
@media print {
  .v-application--wrap {
    display: none !important;
  }

  .v-dialog__content--active {
    display: block !important;
    position: relative !important;

    .v-dialog {
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      box-shadow: none !important;
      max-height: none !important;
      max-width: none !important;
      margin: 0;
      border: none;

      .v-card {
        box-shadow: none;
        border: none;
      }

      .v-data-table {
        .v-data-table__wrapper {
          height: 100% !important;
          overflow: visible !important;
          page-break-inside: auto;
        }
      }

      #signatures-box {
        page-break-inside: avoid;
      }
    }
  }
  thead {
    display: table-row-group;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
}
</style>

<style lang="scss" scoped>
.details-title {
  position: relative;
  .service {
    position: relative;
    right: -4%;
  }
}
.border-none td {
  border-bottom: none !important;
}
.line-through {
  text-decoration: line-through;
}
</style>
