<template>
  <div>
    <v-menu v-if="order_actions.length && !loading" content-class=" br-16">
      <template v-slot:activator="{ on }">
        <v-btn v-bind="props" rounded v-on="on">
          <span>{{ button_text }} </span
          ><v-icon v-if="!loading" class="ml-2">mdi-chevron-down</v-icon
          ><v-progress-circular v-else indeterminate color="primary" />
        </v-btn>
      </template>
      <v-list class="py-0">
        <v-list-item
          v-for="action in order_actions"
          :key="action.text"
          @click="action.callback()"
        >
          <v-list-item-title class="overflow-visible">
            <div class="d-flex align-center">
              <span
                style="height: 20px; width: 20px; border-radius: 50%"
                :class="['mr-2', action.color]"
              />
              <span v-html="action.text" class="mr-2" />
              <v-chip
                v-if="action.beta"
                label
                small
                class="mr-2"
                @click.stop.prevent="action.help_callback()"
                >Beta</v-chip
              >
              <v-icon
                v-if="action.help_callback"
                class="ml-2 pulse--round-icon"
                @click.stop.prevent="action.help_callback()"
                >mdi-help-circle</v-icon
              >
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn v-else class="mx-1 flex-shrink-1" disabled
      >Order Actions<v-progress-circular
        v-if="loading"
        :size="15"
        indeterminate
        color="white"
      ></v-progress-circular
    ></v-btn>
    <v-dialog v-model="show_collaboration_help_dialog" width="500">
      <v-card class="br-16">
        <v-card-title class="primary white--text text-left">
          <p class="text-h3 font-weight-bold">
            <v-icon class="mr-2" color="white" v-text="'$help'" />Help
          </p>
        </v-card-title>

        <v-card-text>
          You can mark an order as <b>Collaborative</b> so that you can make it
          available to other shops to deliver on your behalf. Collaborative
          orders will appear on the delivery planner map and you will receive a
          notification if someone claims the order.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-end">
          <v-btn
            color="primary"
            text
            @click="show_collaboration_help_dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters } from "vuex";
import { ORDER_STATUSES } from "../dashboard_data";
import { DINING_SERVICE_TYPES, RETAIL_SERVICE_TYPES } from "@/shared/services";

import {
  add_order_to_collaboration,
  close_collaboration_order,
} from "@/requests";

export default {
  name: "OrderDetailsActions",
  props: {
    order: {
      type: Object,
      default: null,
    },
    update_order_cb: {
      type: Function,
      default: null,
    },
    loading: { type: Boolean, default: false },
    // Activator button (v-btn) props
    props: {
      type: Object,
      default: null,
    },
    button_text: {
      type: String,
      default: "Update",
    },
  },
  data() {
    return {
      show_collaboration_help_dialog: false,
      DINING_SERVICE_TYPES,
      ORDER_STATUSES,
      RETAIL_SERVICE_TYPES,
    };
  },
  computed: {
    ...mapState(["server_url"]),
    ...mapState("AdminStore", ["shop_id"]),
    ...mapGetters("AdminStore", [
      "shop_details_for_order_collaboration",
      "shop_active_brand_ids",
    ]),
    order_actions: function () {
      const actions = [];
      this.parse_can_mark_as_new(actions);
      this.parse_can_mark_as_processing(actions);
      this.parse_can_mark_as_ready(actions);
      this.parse_can_mark_as_ready__collaboration(actions);
      this.parse_can_close_collaboration(actions);
      this.parse_can_mark_as_planned(actions);
      this.parse_can_mark_as_returned(actions);
      this.parse_can_mark_as_completed(actions);

      return actions;
    },
  },
  methods: {
    parse_can_mark_as_new(actions) {
      const component = this;
      const order = this.order;

      const can_mark_as_new = order.status !== "new";

      if (can_mark_as_new) {
        actions.push({
          text: "Mark as <b>New</b>",
          color: "new",
          callback: function () {
            component.update_order_cb(
              order.id,
              { status: "new" },
              { close_modal: true }
            );
          },
        });
      }
    },
    parse_can_mark_as_ready(actions) {
      const component = this;
      const order = this.order;

      const can_mark_as_ready = [
        () => RETAIL_SERVICE_TYPES.includes(order.service),
        () => order.status !== "ready",
        () =>
          !order.collaboration_order_id ||
          order?.collaboration_status?.status === "returned",
      ].every((con) => con());

      if (can_mark_as_ready) {
        actions.push({
          text: "Mark as <b>Ready</b>",
          color: "ready",
          callback: function () {
            component.update_order_cb(
              order.id,
              { status: "ready" },
              { close_modal: true }
            );
          },
        });
      }
    },
    parse_can_mark_as_ready__collaboration(actions) {
      const component = this;
      const order = this.order;

      const can_mark_as_ready__collaboration = [
        () => order.service === "delivery",
        () => !moment().isAfter(moment(order.fulfilment_date), "day"),
        () =>
          !order.collaboration_order_id ||
          order?.collaboration_status?.status === "returned",
      ].every((con) => con());

      if (can_mark_as_ready__collaboration) {
        actions.push({
          beta: true,
          text: "Mark as <b>Ready + collab</b>",
          color: "ready",
          callback: function () {
            component.add_order_to_collaboration();
          },
          help_callback: function () {
            component.open_collaboration_help_dialog();
          },
        });
      }
    },
    parse_can_close_collaboration(actions) {
      const component = this;
      const order = this.order;

      const can_close_collaboration = [
        () => RETAIL_SERVICE_TYPES.includes(order.service),
        () => order.status === "ready",
        () => order?.collaboration_status?.status === "new",
      ].every((con) => con());

      if (can_close_collaboration) {
        actions.push({
          text: "Close collaboration",
          callback: async () => {
            this.loading = true;
            try {
              await close_collaboration_order(
                component.shop_id,
                order.id,
                order.collaboration_order_id
              );
              component.$emit("close-modal");
            } catch (e) {
              console.error(e);
            } finally {
              this.loading = false;
            }
          },
        });
      }
    },
    async add_order_to_collaboration() {
      this.loading = true;
      try {
        const order = {
          order_no: this.order.order_no,
          customer: this.order.customer,
          delivery: this.order.delivery,
          fulfilment_date: this.order.fulfilment_date,
          service: this.order.service,
          total: this.order.total,
          status: "ready",
        };
        await add_order_to_collaboration({
          brand_ids: this.shop_active_brand_ids,
          order_id: this.order.id,

          shop: this.shop_details_for_order_collaboration,
          ...order,
        });
        this.$emit("close-modal");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    open_collaboration_help_dialog() {
      this.show_collaboration_help_dialog = true;
    },
    parse_can_mark_as_processing(actions) {
      const component = this;
      const order = this.order;
      const is_hospitality = [
        "takeaway",
        "hospitality_delivery",
        "in_house",
      ].includes(this.order.service);

      const can_mark_as_processing = order.status !== "processing";

      if (can_mark_as_processing) {
        actions.push({
          text: `Mark as <b>${is_hospitality ? "Preparing" : "Processing"}</b>`,
          color: "processing",
          callback: function () {
            component.update_order_cb(
              order.id,
              { status: "processing" },
              { close_modal: true }
            );
          },
        });
      }
    },
    parse_can_mark_as_completed(actions) {
      const component = this;
      const order = this.order;

      const can_mark_as_completed =
        order.status.id !== "completed" &&
        order.collaboration_status?.status !== "claimed";

      if (can_mark_as_completed) {
        actions.push({
          text: "Mark as <b>Completed</b>",
          color: "completed",
          callback: function () {
            component.update_order_cb(
              order.id,
              { status: "completed" },
              { close_modal: true }
            );
          },
        });
      }
    },
    parse_can_mark_as_planned(actions) {
      const component = this;
      const order = this.order;

      const can_mark_as_planned =
        RETAIL_SERVICE_TYPES.includes(order.service) &&
        order.status !== "delivery_planned" &&
        order.collaboration_status?.status !== "claimed";

      if (can_mark_as_planned) {
        actions.push({
          text: "Mark as <b>Planned</b>",
          color: "delivery_planned",
          callback: function () {
            component.update_order_cb(
              order.id,
              { status: "delivery_planned" },
              { close_modal: true }
            );
          },
        });
      }
    },
    parse_can_mark_as_returned(actions) {
      const component = this;
      const order = this.order;

      const can_mark_as_returned =
        RETAIL_SERVICE_TYPES.includes(order.service) &&
        order.status !== "delivery_returned" &&
        order.collaboration_status?.status !== "claimed";

      if (can_mark_as_returned) {
        actions.push({
          text: "Mark as <b>Returned</b>",
          color: "delivery_returned",
          callback: function () {
            component.update_order_cb(
              order.id,
              { status: "delivery_returned" },
              { close_modal: true }
            );
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item__title ::v-deep .status-indicator {
  height: 20px;
  width: 20px;
}
</style>
