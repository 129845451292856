<template>
  <div>
    <v-icon color="yellow" small v-text="'$clock-elapsed'" />
    <span
      :class="[
        `py-1 yellow--text ma-0 display-1 font-weight-bold text-wrap text-center`,
      ]"
    >
      {{ fuzzy_date(created_at).date }}
    </span>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "InHouseTileFuzzyDate",
  data() {
    return {
      moment,
    };
  },
  props: {
    created_at: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    fuzzy_date(created_at) {
      const now = moment();
      const created = moment.unix(created_at);
      let is_late = false;
      if (now.diff(created, "minutes") > 20) {
        is_late = true;
      }
      return {
        is_late,
        date: created.fromNow(),
      };
    },
  },
};
</script>

<style></style>
