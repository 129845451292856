<template>
  <v-container fluid tag="section" class="pt-0">
    <DiningOrdersSettings />
    <div class="mt-4 py-2 d-flex white br-8 align-center justify-space-around">
      <div
        :key="update_timer"
        class="text-h2 ml-4 font-weight-bold primary--text"
      >
        {{ time_now() }}
      </div>
      <v-tabs
        v-model="active_tab"
        color="transparent"
        class="status-tabs"
        align-with-title
        grow
      >
        <v-tab :ripple="false">
          <template #default>
            <div
              class="text-h3 font-weight-medium dark-grey--text text--darken-1"
            >
              New
              <span
                v-if="total_displayed_orders(TABS.NEW) > 0"
                class="--no-indicator ml-2"
                >{{ total_displayed_orders(TABS.NEW) }}</span
              >
            </div>
          </template>
        </v-tab>
        <v-tab :ripple="false">
          <template #default>
            <div
              class="text-h3 font-weight-medium dark-grey--text text--darken-1"
            >
              Scheduled
              <span
                v-if="total_displayed_orders(TABS.SCHEDULED) > 0"
                class="--no-indicator ml-2"
                >{{ total_displayed_orders(TABS.SCHEDULED) }}</span
              >
            </div>
          </template>
        </v-tab>
        <v-tab :ripple="false">
          <template #default>
            <div
              class="text-h3 font-weight-medium dark-grey--text text--darken-1"
            >
              Preparing
              <span
                v-if="total_displayed_orders(TABS.PROCESSING) > 0"
                class="--no-indicator ml-2"
                >{{ total_displayed_orders(TABS.PROCESSING) }}</span
              >
            </div>
          </template>
        </v-tab>
        <v-tab :ripple="false">
          <template #default>
            <div
              class="text-h3 font-weight-medium dark-grey--text text--darken-1"
            >
              Completed
            </div>
          </template>
        </v-tab>
      </v-tabs>
    </div>

    <v-tabs-items v-model="active_tab" class="transparent">
      <v-tab-item v-for="item in order_tabs" :key="item.name" class="px-4">
        <v-row v-if="active_tab === TABS.COMPLETED" align="center">
          <v-col cols="12" md="2">
            <v-dialog
              :retain-focus="false"
              ref="date_dialog"
              v-model="date_modal"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formatted_date"
                  label="Select date"
                  prepend-icon="event"
                  hide-details
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="picker_date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" rounded @click="date_modal = false"
                  >Cancel</v-btn
                >
                <v-btn
                  rounded
                  color="primary"
                  @click="
                    selected_date = picker_date;
                    date_modal = false;
                  "
                  >OK</v-btn
                >
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" md="6" class="d-flex align-center"
            ><v-btn
              color="primary"
              rounded
              :disabled="!selected_date"
              @click="fetch_all_completed_orders()"
              >refresh
            </v-btn></v-col
          >
        </v-row>
        <v-row v-if="dining_orders_split_view && number_of_services >= 2">
          <v-col
            cols="12"
            :sm="number_of_services > 2 ? 12 : 12 / number_of_services"
            :md="12 / number_of_services"
            v-if="has_in_house_service"
          >
            <DiningOrdersDisplay
              @show-order-details="(order) => (selected_order = order)"
              card
              in_house
              :service_type="SERVICE_TYPES_INFO.in_house"
              :orders="displayed_in_house_orders"
            />
          </v-col>
          <v-col
            cols="12"
            :sm="number_of_services > 2 ? 12 : 12 / number_of_services"
            :md="12 / number_of_services"
            v-if="has_takeaway_service"
          >
            <DiningOrdersDisplay
              @show-order-details="(order) => (selected_order = order)"
              card
              takeaway
              :service_type="SERVICE_TYPES_INFO.takeaway"
              :orders="displayed_takeaway_orders"
            />
          </v-col>
          <v-col
            cols="12"
            :sm="number_of_services > 2 ? 12 : 12 / number_of_services"
            :md="12 / number_of_services"
            v-if="has_hospitality_delivery_service"
          >
            <DiningOrdersDisplay
              @show-order-details="(order) => (selected_order = order)"
              card
              hospitality_delivery
              :service_type="SERVICE_TYPES_INFO.takeaway"
              :orders="displayed_hospitality_delivery_orders"
            />
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            <DiningOrdersDisplay
              :orders="all_displayed_orders"
              @show-order-details="(order) => (selected_order = order)"
            />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <OrderDetails
      @close="selected_order = null"
      :order="fulfilment_order ? null : selected_order"
      @confirm_order="
        (show_confirm_fulfilment = true), (fulfilment_order = selected_order)
      "
    />
    <ConfirmFulfilmentDate
      :visible="show_confirm_fulfilment"
      :order="fulfilment_order"
      :updating_fulfilment_date="updating_fulfilment_date"
      @confirm_fulfilment_date="confirm_fulfilment_date"
      @close="
        (show_confirm_fulfilment = false),
          (fulfilment_order = null),
          (selected_order = null)
      "
      @back_to_order="
        (selected_order = fulfilment_order),
          (show_confirm_fulfilment = false),
          (fulfilment_order = null)
      "
    />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment";

import { db } from "@/db";

import { SERVICE_TYPES_INFO } from "@/shared/services";

import OrderDetails from "@/views/dashboard/components/OrderDetails";
import ConfirmFulfilmentDate from "../components/ConfirmFulfilmentDate";
import DiningOrdersDisplay from "@/views/dashboard/components/dining_orders/DiningOrdersDisplay";
import DiningOrdersSettings from "@/views/dashboard/components/dining_orders/DiningOrdersSettings";

import { update_orders } from "@/requests";

const TABS = {
  NEW: 0,
  SCHEDULED: 1,
  PROCESSING: 2,
  COMPLETED: 3,
};

const DATE_FORMAT = "YYYY-MM-DD";

export default {
  name: "DiningOrders",
  data() {
    return {
      moment,

      TABS,
      SERVICE_TYPES_INFO,
      timer: null,
      update_timer: 0,

      status_filter: "incomplete",
      active_tab: 0,
      order_tabs: [
        { id: TABS.NEW, name: "new" },
        { id: TABS.SCHEDULED, name: "scheduled" },
        { id: TABS.PROCESSING, name: "processing" },
        { id: TABS.COMPLETED, name: "completed" },
      ],

      completed_in_house_orders: [],
      completed_takeaway_orders: [],
      completed_hospitality_delivery_orders: [],

      picker_date: null,
      date_modal: false,

      show_confirm_fulfilment: false,
      updating_fulfilment_date: false,

      selected_order: null,
      fulfilment_order: null,
      selected_date: moment().format("YYYY-MM-DD"),
    };
  },
  components: {
    OrderDetails,
    DiningOrdersDisplay,
    DiningOrdersSettings,
    ConfirmFulfilmentDate,
  },
  computed: {
    ...mapState("AdminStore", [
      "in_house_orders",
      "shop_id",
      "dining_orders_split_view",
    ]),
    ...mapGetters("AdminStore", [
      "has_in_house_service",
      "has_takeaway_service",
      "has_hospitality_delivery_service",
      "has_all_dining_services",
      "takeaway_orders",
      "hospitality_delivery_orders",
    ]),
    number_of_services() {
      return [
        this.has_in_house_service,
        this.has_takeaway_service,
        this.has_hospitality_delivery_service,
      ].filter((is_active) => is_active).length;
    },
    takeaway_orders_by_status() {
      return {
        [TABS.NEW]: this.filter_orders_by_status(this.takeaway_orders, "new", {
          scheduled: false,
        }),
        [TABS.SCHEDULED]: this.filter_orders_by_status(
          this.takeaway_orders,
          "new",
          { scheduled: true }
        ).sort((a, b) => {
          return (
            moment(a.takeaway_pickup_date, "DD/MM/YYYY").unix() -
            moment(b.takeaway_pickup_date, "DD/MM/YYYY").unix()
          );
        }),
        [TABS.PROCESSING]: this.filter_orders_by_status(
          this.takeaway_orders,
          "processing"
        ),
        [TABS.COMPLETED]: this.completed_takeaway_orders,
      };
    },
    hospitality_delivery_orders_by_status() {
      return {
        [TABS.NEW]: this.filter_orders_by_status(
          this.hospitality_delivery_orders,
          "new",
          { scheduled: false }
        ).sort(),
        [TABS.SCHEDULED]: this.filter_orders_by_status(
          this.hospitality_delivery_orders,
          "new",
          { scheduled: true }
        ).sort((a, b) => {
          return (
            moment(a.takeaway_pickup_date, "DD/MM/YYYY").unix() -
            moment(b.takeaway_pickup_date, "DD/MM/YYYY").unix()
          );
        }),
        [TABS.PROCESSING]: this.filter_orders_by_status(
          this.hospitality_delivery_orders,
          "processing"
        ),
        [TABS.COMPLETED]: this.completed_hospitality_delivery_orders,
      };
    },
    in_house_orders_by_status() {
      return {
        [TABS.NEW]: this.filter_orders_by_status(this.in_house_orders, "new"),
        [TABS.PROCESSING]: this.filter_orders_by_status(
          this.in_house_orders,
          "processing"
        ),
        [TABS.COMPLETED]: this.completed_in_house_orders,
      };
    },
    displayed_takeaway_orders() {
      return this.takeaway_orders_by_status[this.active_tab] || [];
    },
    displayed_in_house_orders() {
      return this.in_house_orders_by_status[this.active_tab] || [];
    },
    displayed_hospitality_delivery_orders() {
      return this.hospitality_delivery_orders_by_status[this.active_tab] || [];
    },
    all_displayed_orders() {
      return this.displayed_in_house_orders
        .concat(this.displayed_takeaway_orders)
        .concat(this.displayed_hospitality_delivery_orders)
        .sort((a, b) => a.order_no - b.order_no);
    },
    formatted_date() {
      return moment(this.selected_date).format("DD/MM/YYYY");
    },
    unix_date() {
      return moment(this.selected_date).unix();
    },
  },
  created() {
    this.timer = setInterval(() => {
      this.update_timer += 1;
    }, 10000);
  },
  watch: {
    active_tab(tab_id) {
      if (tab_id === TABS.COMPLETED) {
        this.fetch_all_completed_orders();
      }
    },
    selected_date() {
      this.fetch_all_completed_orders();
    },
  },
  methods: {
    time_now() {
      return moment().format("h:mma");
    },
    total_displayed_orders(tab_id) {
      return [
        ...(this.takeaway_orders_by_status[tab_id] || []),
        ...(this.in_house_orders_by_status[tab_id] || []),
        ...(this.hospitality_delivery_orders_by_status[tab_id] || []),
      ].length;
    },
    filter_orders_by_status(
      orders,
      status,
      options = { scheduled: undefined }
    ) {
      const today = moment();
      return orders
        .filter((order) => {
          if (options.scheduled) {
            return (
              order.status === status &&
              order.fulfilment_date &&
              moment(order.fulfilment_date, DATE_FORMAT).isAfter(today)
            );
          } else if (options.scheduled === false) {
            return (
              order.status === status &&
              (!order.fulfilment_date ||
                moment(order.fulfilment_date, DATE_FORMAT) ===
                  today.format(DATE_FORMAT) ||
                moment(order.fulfilment_date, DATE_FORMAT).isBefore(today))
            );
          }
          return order.status === status;
        })
        .sort((a, b) => a.order_no - b.order_no);
    },
    fetch_completed_orders(date, service) {
      return db
        .collection("shops")
        .doc(this.shop_id)
        .collection("orders")
        .where("service", "==", service)
        .where("status", "==", "completed")
        .where("created_at", ">=", new Date(date + "T00:00:00"))
        .where("created_at", "<=", new Date(date + "T23:59:59"))
        .get()
        .then((snapshot) => snapshot.docs.map((doc) => doc.data()));
    },
    async fetch_completed_in_house_orders() {
      let date = moment(this.selected_date).format("YYYY-MM-DD");

      this.completed_in_house_orders = await this.fetch_completed_orders(
        date,
        "in_house"
      );
    },
    async fetch_completed_takeaway_orders() {
      let date = moment(this.selected_date).format("YYYY-MM-DD");

      this.completed_takeaway_orders = await this.fetch_completed_orders(
        date,
        "takeaway"
      );
    },
    async fetch_completed_hospitality_delivery_orders() {
      let date = moment(this.selected_date).format("YYYY-MM-DD");

      this.completed_hospitality_delivery_orders =
        await this.fetch_completed_orders(date, "hospitality_delivery");
    },
    async fetch_all_completed_orders() {
      await this.fetch_completed_in_house_orders();
      await this.fetch_completed_takeaway_orders();
      await this.fetch_completed_hospitality_delivery_orders();
    },
    async confirm_fulfilment_date({
      brand_id,
      order_id,
      shop_name,
      fulfilment_date,
      email_message = null,
    }) {
      this.updating_fulfilment_date = true;

      const confirmation = {
        brand_id,
        shop_name,
        email_message,
        old_val: this.order?.fulfilment_date
          ? this.format_fulfilment(this.order.fulfilment_date)
          : null,
        new_val: this.format_fulfilment(fulfilment_date),
        type: "fulfilment_date",
      };

      const updates = {
        fulfilment_date,
        confirmation,
      };

      try {
        await update_orders(this.shop_id, [order_id], updates);
        this.show_confirm_fulfilment = false;
        this.snackbar = true;
      } catch (error) {
        console.error("Orders/update_orders ", error);
      } finally {
        this.updating_fulfilment_date = false;
        this.selected_order = null;
        this.fulfilment_order = null;
      }
    },
    format_fulfilment(fulfilment) {
      if (!fulfilment) return "Not confirmed";
      if (fulfilment.includes(":")) {
        return moment(fulfilment).format("DD/MM/YYYY HH:mm");
      }
      return moment(fulfilment).format("DD/MM/YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
.status-tabs ::v-deep.v-tab {
  &:hover {
    &:before {
      opacity: 0 !important;
    }
  }
}
.status-tabs ::v-deep.v-tab {
  div {
    display: flex;
    align-items: center;
    border-radius: 9999px;
    padding: 6px 30px;
    transition: all 0.125s ease-in-out;
  }
  .--no-indicator {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-block;
    color: white;
    display: inline-flex;
    justify-content: center;
    vertical-align: middle;
    font-size: 0.6em;
    line-height: 20px;
    background-color: var(--v-accent-base);
  }

  &:hover {
    div {
      background-color: #dff4f5;
    }
  }

  &.v-tab--active {
    div {
      background-color: #dff4f5;
      color: #2bd7de !important;
    }
  }
}
</style>
