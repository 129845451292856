<template>
  <v-container class="pa-0 full-height d-flex flex-column">
    <v-card
      :ripple="false"
      elevation="1"
      class="full-height d-flex flex-column my-2 br-16"
      @click.stop="$emit('show-order-details', order)"
    >
      <v-card-title class="primary d-flex align-center position-relative py-2">
        <v-row class="py-0">
          <v-col
            cols="12"
            class="d-flex justify-center align-center py-0 w-100"
          >
            <template v-if="order.status !== 'completed'">
              <TakeawayTileCountdown
                v-if="
                  order.service === SERVICE_TYPES_INFO.takeaway.id ||
                  order.service === SERVICE_TYPES_INFO.hospitality_delivery.id
                "
                :key="update_fuzzy_date"
                :order="order"
              />
              <InHouseTileFuzzyDate
                v-else
                :key="update_fuzzy_date"
                :created_at="order.created_at.seconds"
              />
            </template>
            <template v-else>
              <span class="white--text">{{
                moment.unix(order.created_at.seconds).format("DD/MM/YYYY HH:mm")
              }}</span>
            </template>
          </v-col>
          <span class="order-number white--text">#{{ order.order_no }} </span>
        </v-row>
        <div
          class="order-indicator --in-house"
          v-if="order.service === 'in_house'"
        >
          <div>
            <span class="d-block mb-0" :class="[order.service_method]">{{
              order.service_method === "table_service"
                ? "Table"
                : "Collection Point"
            }}</span>
            <span class="d-block">{{ order.table_number }}</span>
          </div>
        </div>
        <div :class="['order-indicator', `--${order.service}`]" v-else>
          <span class="service-type mt-1">{{
            SERVICE_TYPES_INFO[order.service].pretty_name
          }}</span>
          <v-icon style="transform: scale(0.8)" v-text="`$${order.service}`" />
        </div>
      </v-card-title>
      <v-divider />

      <div>
        <v-container
          v-if="order.service == SERVICE_TYPES_INFO.in_house.id"
          class="pa-0"
        >
          <div
            class="d-flex align-center justify-center text-uppercase py-1"
            v-if="order.service_method === 'collecton_point'"
          >
            <span class="display-2">{{ order.customer.name }}</span>
            <span class="caption">Collection point</span>
          </div>
        </v-container>
      </div>
      <v-card-text class="pb-0">
        <div
          class="product-table"
          v-for="item in order.products.slice(0, 5)"
          :key="item.id"
        >
          <div class="product-table-data text-body-1">{{ item.quantity }}x</div>
          <div class="product-table-data font-weight-bold">
            <span class="d-block">{{ item.name }}</span>
            <span
              v-if="item.options && item.options.length"
              class="ml-1 d-block grey--text text--darken-2"
              v-html="
                item.options
                  .map((o) => {
                    if (o.option) return `${o.option}: ${o.name}`;
                    return o.name;
                  })
                  .join('\n')
              "
            />
          </div>
        </div>
        <div v-if="order.products.length > 5">
          <div colspan="2" class="product-table-data font-weight-bold">
            {{ order.products.length - 5 }} more (Click to open)
          </div>
        </div>
      </v-card-text>
      <v-spacer />
      <div class="pa-4 d-flex justify-space-between align-center">
        <OrderDetailsActions
          :order="order"
          :loading="updating_order_id === order.id"
          :update_order_cb="update_order"
          :props="{
            elevation: '0',
            class: 'primary-alt text-uppercase',
          }"
          :button_text="'Update'"
        />
        <template v-if="order.customer.notes">
          <v-badge
            content="1"
            color="accent"
            offset-x="15"
            offset-y="15"
            dark
            overlap
          >
            <v-btn color="primary" fab small elevation="0"
              ><v-icon color="white">mdi-message-reply-text</v-icon></v-btn
            >
          </v-badge>
        </template>
        <div class="text-right">
          <p class="ma-0">
            {{ order.products.length }} item{{
              order.products.length > 1 ? "s" : ""
            }}
          </p>
          <p class="ma-0 font-weight-bold">
            Total: £{{ order.total.toFixed(2) }}
          </p>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import { update_orders } from "@/requests";

import { SERVICE_TYPES_INFO } from "@/shared/services";

import OrderDetailsActions from "@/views/dashboard/components/OrderDetailsActions";
import InHouseTileFuzzyDate from "@/views/dashboard/components/dining_orders/InHouseTileFuzzyDate";
import TakeawayTileCountdown from "@/views/dashboard/components/dining_orders/TakeawayTileCountdown";

export default {
  name: "DiningOrderTile",
  components: {
    OrderDetailsActions,
    InHouseTileFuzzyDate,
    TakeawayTileCountdown,
  },
  props: {
    show_service_type: { type: Boolean, default: true },
    order: { type: Object, required: true },
  },
  data() {
    return {
      loading_id: null,
      SERVICE_TYPES_INFO,
      moment,
      timer: null,
      update_fuzzy_date: 0,
      updating_order_id: null,
      order_details: null,
      order_action_fab: false,
    };
  },
  created() {
    this.timer = setInterval(() => {
      this.update_fuzzy_date += 1;
    }, 30000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  computed: {
    ...mapState("AdminStore", ["shop_id", "dining_orders_split_view"]),
    ...mapGetters("AdminStore", ["has_all_dining_services"]),
  },
  methods: {
    async update_order(order_id, updates) {
      try {
        this.updating_order_id = order_id;
        await update_orders(this.shop_id, [order_id], updates);
      } catch (error) {
        console.error("DiningOrderTile/update_order ", error);
      } finally {
        this.updating_order_id = null;
      }
    },
    google_maps_url({ geolocation }) {
      return `https://www.google.com/maps/search/?api=1&query=${geolocation.lat},${geolocation.lng}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.order-indicator {
  font-size: 12px;
  line-height: 1.2;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 68px;
  width: 68px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid var(--v-primary-base);
  position: absolute;
  left: -10px;

  &.--in-house {
    font-size: 16px;
    color: white;
    background-color: var(--v-primary-base);
    border-color: white;

    .collection_point {
      font-size: 0.7em;
    }

    span {
      position: relative;
      top: 2px;
    }
  }

  &.--takeaway {
    span {
      font-size: 0.9em;
    }
  }
}
.order-number {
  position: absolute;
  right: 0;
  margin-right: 12px;
}
.in_house_order {
  position: relative;
}

.product-table {
  display: flex;
  align-items: center;
}
.product-table-data {
  margin-bottom: 8px;
  display: inline-block;
  font-weight: normal;
  font-size: 1.2rem;
  text-align: left;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:first-of-type {
    min-width: 50px;
  }

  &:last-child {
    font-size: 1rem;
    white-space: pre;
    text-align: left;
  }
}
</style>
