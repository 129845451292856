<template>
  <div>
    <div
      class="d-flex"
      :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
    >
      <div
        v-if="shop_copy.acquire_items.service.in_house"
        class="d-inline-flex white br-8 align-center mr-4 px-4"
      >
        <p class="font-weight-medium mb-0 mr-2">
          In-house:<br />
          (mins)
        </p>
        <v-text-field
          class="mr-4 remove-spinner centered-input"
          v-model="shop_copy.in_house.food_wait_time"
          type="number"
          hide-details
          step="1"
          min="0"
          dense
          single-line
          style="max-width: 50px"
          @input="times_changed = true"
        >
          <template #prepend
            ><v-icon small color="primary" v-text="'$my-orders'"
          /></template>
        </v-text-field>
        <v-text-field
          class="remove-spinner centered-input"
          v-model="shop_copy.in_house.drink_wait_time"
          type="number"
          hide-details
          step="1"
          min="0"
          dense
          single-line
          style="max-width: 50px"
          @input="times_changed = true"
        >
          <template #prepend
            ><v-icon small color="primary" v-text="'$drink'"
          /></template>
        </v-text-field>
      </div>
      <div
        v-if="shop_copy.acquire_items.service.takeaway"
        class="d-inline-flex white br-8 align-center mr-4 px-4"
      >
        <p class="font-weight-medium mb-0 mr-2">
          Takeaway:<br />
          (mins)
        </p>
        <v-text-field
          class="remove-spinner centered-input"
          v-model="shop_copy.takeaway.wait_time"
          type="number"
          hide-details
          step="1"
          min="0"
          dense
          single-line
          style="max-width: 50px"
          @input="times_changed = true"
        >
          <template #prepend
            ><v-icon small color="primary" v-text="'$clock-elapsed'"
          /></template>
        </v-text-field>
      </div>
      <div
        v-if="shop_copy.acquire_items.service.hospitality_delivery"
        class="d-inline-flex white br-8 align-center mr-4 px-4"
      >
        <p class="font-weight-medium mb-0 mr-2">
          Delivery:<br />
          (mins)
        </p>
        <v-text-field
          class="remove-spinner centered-input"
          v-model="shop_copy.hospitality_delivery.wait_time"
          type="number"
          hide-details
          step="1"
          min="0"
          dense
          single-line
          style="max-width: 50px"
          @input="times_changed = true"
        >
          <template #prepend
            ><v-icon small color="primary" v-text="'$clock-elapsed'"
          /></template>
        </v-text-field>
      </div>
      <div class="d-inline-flex white br-8 align-center mr-4">
        <v-btn
          :class="{ 'pulse--round-icon': times_changed }"
          class="flex-self mr-0"
          color="primary"
          icon
          large
          @click="update_shop_config()"
          :disabled="!times_changed"
        >
          <v-icon>mdi-check-circle</v-icon>
        </v-btn>
      </div>
      <div class="d-inline-flex align-center flex-grow-1 justify-end">
        <div v-if="shop.allow_tip" class="d-inline-flex px-3 py-2 --tips mr-4">
          <v-icon small color="primary" class="mr-2">mdi-heart</v-icon>
          <p class="font-weight-medium mb-0 mr-2">Total Tip (Today):</p>
          <p class="mb-0">£{{ today_tips.toFixed(2) }}</p>
        </div>
        <span class="mr-2"> Split Screen View </span>
        <v-switch class="mr-1" v-model="split_view"> </v-switch>
      </div>
    </div>

    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      color="yellow"
      top
      right
      transition="slide-x-reverse-transition"
    >
      <b class="black--text">Changes were saved successfully</b>
      <template v-slot:action>
        <v-icon color="black" @click="snackbar = false"
          >mdi-close-circle-outline</v-icon
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters, mapActions } from "vuex";
import firebase from "firebase/app";
import cloneDeep from "lodash/cloneDeep";
import { db } from "@/db";

export default {
  name: "DiningOrdersSettings",
  data() {
    return {
      shop_copy: null,
      snackbar: false,
      times_changed: false,
    };
  },
  computed: {
    ...mapState("AdminStore", [
      "shop",
      "shop_id",
      "dining_orders_settings_panel",
      "dining_orders_split_view",
      "tips",
    ]),
    ...mapGetters("AdminStore", [
      "has_in_house_service",
      "has_takeaway_service",
      "has_hospitality_delivery_service",
      "has_all_dining_services",
    ]),
    number_of_services() {
      return [
        this.has_in_house_service,
        this.has_takeaway_service,
        this.has_hospitality_delivery_service,
      ].filter((is_active) => is_active).length;
    },
    split_view: {
      get() {
        return this.dining_orders_split_view;
      },
      set(value) {
        this.toggle_dining_orders_split_view({ value });
      },
    },
    panel: {
      get() {
        return this.dining_orders_settings_panel;
      },
      set(payload) {
        this.toggle_dining_orders_settings_panel({ payload });
      },
    },
    today_tips() {
      const today = moment().format("YYYY-MM-DD");
      return this.tips?.[today] ?? 0.0;
    },
  },
  methods: {
    ...mapActions("AdminStore", [
      "toggle_dining_orders_settings_panel",
      "toggle_dining_orders_split_view",
    ]),
    async update_shop_config() {
      try {
        await db
          .collection("shops")
          .doc(this.shop_id)
          .update({
            ...this.shop_copy,
            updated_at: firebase.firestore.FieldValue.serverTimestamp(),
          });
        this.snackbar = true;
        this.times_changed = false;
      } catch (error) {
        console.error("DiningOrdersSettings/update_shop_config ", error);
      }
    },
  },
  watch: {
    shop: {
      handler(shop) {
        this.shop_copy = cloneDeep(shop);
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .centered-input input {
  text-align: center;
}
.--tips {
  border-radius: 20px;
  border: 1px solid var(--v-primary-base);
}
</style>
