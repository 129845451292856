<template>
  <div>
    <p class="ma-0 yellow--text">
      <span v-if="is_customer_late">Not Collected</span>
      <span v-else-if="is_scheduled"
        >{{ moment(pickup_date, DATE_FORMAT).format("Do MMM") }}
        {{ pickup_time }}</span
      >
      <span v-else-if="!pickup_time && is_asap">ASAP</span>
      <span v-else
        ><v-icon color="white" v-text="'$calendar'" class="mr-2" />
        {{ pickup_time }}</span
      >
    </p>
  </div>
</template>
<script>
import moment from "moment";

const DATE_FORMAT = "YYYY-MM-DD";

export default {
  name: "TakeawayTileCountdown",
  data() {
    return {
      moment,
      DATE_FORMAT,
    };
  },
  props: {
    order: { type: Object, required: true },
  },
  computed: {
    fulfilment_data() {
      let fulfilment_date = null;
      let date = null;
      let time = null;

      if (this.order?.fulfilment_date) {
        fulfilment_date = this.order.fulfilment_date;
        [date, time] = fulfilment_date.split(" ");
      }

      return {
        date,
        time,
      };
    },
    is_asap() {
      return this.order.takeaway_fulfil_asap;
    },
    pickup_date() {
      return this.fulfilment_data?.date ?? null;
    },
    pickup_time() {
      return this.fulfilment_data?.time ?? null;
    },
    is_scheduled() {
      if (!this.pickup_date) return false;
      return moment(this.pickup_date, DATE_FORMAT).isAfter(moment());
    },
    is_customer_late() {
      const today_date = moment().format(DATE_FORMAT);
      const today = moment(today_date, DATE_FORMAT);

      if (!this.pickup_date) return false;
      const pickup_date = moment(this.pickup_date, DATE_FORMAT);
      if (pickup_date.isSame(today)) return false;

      return moment(this.pickup_date, DATE_FORMAT).isBefore(today);
    },
  },
  methods: {
    countdown_color(pickup_time, pickup_date) {
      if (
        pickup_date &&
        moment(this.pickup_date, DATE_FORMAT).format(DATE_FORMAT) !==
          moment().format(DATE_FORMAT)
      ) {
        return "";
      }
      const now = moment();
      const pickup = [now.format("DD-MM-YYYY"), pickup_time].join(" ");
      const moment_pickup = moment(pickup, "DD-MM-YYYY HH:mm");
      const difference = moment_pickup.diff(now, "m");
      let color;

      if (difference < 10) {
        color = "accent--text";
      } else if (difference < 20) {
        color = "yellow--text";
      } else {
        color = "white--text";
      }
      return color;
    },
  },
};
</script>
