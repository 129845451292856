export const PRODUCT_ALLERGENS = [
  "Celery",
  "Wheat",
  "Cereals",
  "Gluten",
  "Gelatin",
  "Crustaceans",
  "Eggs",
  "Fish",
  "Lupin",
  "Milk",
  "Molluscs",
  "Mustard",
  "Tree nuts",
  "Nuts",
  "Peanuts",
  "Sesame seeds",
  "Soybeans",
  "Sulphur dioxide",
  "Please consult us for allergen advice and input notes at the checkout",
];

export const ORDER_STATUSES = {
  new: { id: "new", name: "New" },
  processing: { id: "processing", name: "Processing" },
  ready: { id: "ready", name: "Ready" },
  delivery_planned: { id: "delivery_planned", name: "Planned" },
  delivery_returned: {
    id: "delivery_returned",
    name: "Returned",
  },
  completed: {
    id: "completed",
    name: "Completed",
  },
};

export const SHOP_SERVICES = {
  collection: "Collection",
  delivery: "Delivery",
  in_house: "In House",
  takeaway: "Takeaway",
  hospitality_delivery: "Delivery",
};
