<template>
  <v-container fluid class="pa-0">
    <v-card v-if="card" color="transparent" flat>
      <v-card-title class="justify-center">
        <h3 class="display-2 text--secondary font-weight-bold pa-0">
          <div v-if="in_house">In House</div>
          <div v-if="takeaway">Takeaway</div>
          <div v-if="hospitality_delivery">Delivery</div>
        </h3>
      </v-card-title>
      <v-container class="pa-0">
        <v-row v-if="orders.length">
          <v-col cols="12" v-for="order in orders" :key="order.id">
            <DiningOrderTile
              @show-order-details="
                (order) => $emit('show-order-details', order)
              "
              :order="order"
            />
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col class="mt-6" cols="12"
            ><p class="text-h4 text-center">No orders to display</p></v-col
          >
        </v-row>
      </v-container>
    </v-card>
    <v-container v-else fluid class="pa-0">
      <v-row v-if="orders.length">
        <v-col
          cols="12"
          sm="6"
          md="4"
          xl="3"
          v-for="order in orders"
          :key="order.id"
        >
          <DiningOrderTile
            @show-order-details="(order) => $emit('show-order-details', order)"
            :order="order"
          />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12"
          ><p class="text-h4 font-weight-bold">No orders to display</p></v-col
        >
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import DiningOrderTile from "@/views/dashboard/components/dining_orders/DiningOrderTile";

export default {
  name: "DiningOrdersDisplay",
  components: { DiningOrderTile },
  props: {
    card: { type: Boolean, default: false },
    orders: { type: Array, required: true },
    takeaway: { type: Boolean, default: false },
    hospitality_delivery: { type: Boolean, default: false },
    in_house: { type: Boolean, default: false },
  },
  data() {
    return {
      panel: 0,
    };
  },
};
</script>
